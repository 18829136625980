import React, { useState } from 'react'
import SideBar from './SideBar';
import axiosClient from '../axios';
import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
import CSVDownload from "./CSVDownload"
import loaderimg from '../assets/images/loader.gif'
import { dateConvert } from '../dateConverter';
import '../assets/css/userTable.css'
import Pagination from './Pagination';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TextField } from '@mui/material';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const UserUploadCsv = () => {
    const [totalPage, setTotalPage] = useState(0)
    const [data, setData] = useState([])
    const [filterInfo, setFilterInfo] = useState({
        page: 0,
        limit: 100,
    })
    const [bulkData, setBulkData] = useState([])
    const [bulkPopup, setBulkPopup] = useState(false);
    const [dataUpdated, setDataUpdated] = useState(false)
    const [loader, setLoader] = useState(false);
    const [csvDate, setCsvDate] = useState("")
    const [beforeData, setBeforeData] = useState("true")
    const [missingData, setMissingData] = useState([])


    // Helper function to read file as text
    const readFileAsText = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => resolve(e.target.result);
            reader.onerror = reject;
            reader.readAsText(file);
        });
    };

    const handleBulkCsv = async (event) => {
        setLoader(true);
        try {
            setDataUpdated(false);
            const file = event.target.files[0];
            if (!file) return;

            const text = await readFileAsText(file); // Await file reading

            // Expected headers
            const expectedHeaders = ["emailId", "phoneNumber", "userId"];
            const fileHeaders = text.split("\n")[0].split(",").map(header => header.trim());
            // Validate headers
            if (!expectedHeaders.every((header, index) => header === fileHeaders[index])) {
                alert("Invalid CSV Format: Headers should be 'emailId, phoneNumber'");
                return;
            }
            const rows = text.split("\n").slice(1); // Remove header

            const formattedData = rows
                .map((row) => row.split(",")) // Split by comma
                .filter((cols) => cols.length >= 2) // Ensure valid rows
                .map(([emailId = '', phoneNumber = '', userId = '']) => ({
                    emailId: emailId.trim(),
                    phoneNumber: phoneNumber.trim(),
                    userId: userId.trim()
                }));

            if (formattedData.length === 0) {
                alert("Invalid CSV Format")
                return
            }
            console.log("formattedData", formattedData)
            setBulkData(formattedData);
            setBulkPopup(true);

        } catch (err) {
            console.error("Error:", err);
        } finally {
            setLoader(false);

        }
    };

    const handleMisedCsvData = (filerterData) => {
        const missingData = bulkData.filter(
            bulkItem => !filerterData.some(
                filterItem => filterItem.emailId === bulkItem.emailId
            )
        );
        setMissingData(missingData);
    };



    const handleBulkPopup = () => {
        setBulkPopup(!bulkPopup)
        setBulkData([]);
        setDataUpdated(false)
    }

    const handleUploadedCsv = async () => {

        let userData = {
            SelectedDate: csvDate ? dateConvert(csvDate) : "",
            bulkData: bulkData,
            beforeData: beforeData
        }
        setLoader(true);
        await axiosClient.post("/user-uploaded-csv-data", userData)
            .then((res) => {
                setLoader(false);
                // setBulkData(res.data.response)
                setData(res.data.response)
                setTotalPage(res.data.response.length)
                setDataUpdated(true);
                setBulkPopup(false);

                handleMisedCsvData(res.data.response)
            })
            .catch((err) => {
                console.log('res-', err.response.data.messae)
                alert(err.response.data.messae)
                setLoader(false);
                setDataUpdated(true);
                setBulkPopup(false);

            })
    }
    const handleDownloadCsv = (data, name) => {
        try {
            const dataToExport = data.map((val, key) => ({
                "Email Id": val?.emailId,
                "Phone Number": val?.phoneNumber,
                "last_login_at": val?.last_login_at,
                userId: val?._id
            }));
            CSVDownload(dataToExport, name);
        } catch (error) {
            console.error('Error downloading CSV:', error);
        }
    }
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 100; // Adjust this value as needed
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = bulkData.slice(indexOfFirstItem, indexOfLastItem);
    return (
        <>
            <SideBar handleSideBar={true} activeTab={"UploadUserCsv"} />
            <div className="screen">

                <div className='dFlex'>
                    <h2 className='screenHeading catHead'>User Upload Csv</h2>
                </div>

                <div className="upload-user-csv-flex">
                    <div className="user-upload">
                        <form>
                            <input type={"file"} id='bulkdepositCsv' onChange={handleBulkCsv} accept={".csv"} style={{ display: "none" }} />
                            <label htmlFor="bulkdepositCsv" className='userCsvButton'>Upload Csv</label>
                        </form>
                    </div>

                    {dataUpdated && data?.length > 0 &&
                        <div className='csv-download' onClick={() => handleDownloadCsv(data, "matchedUser.csv")}>
                            <p>DownloadCsv </p>
                        </div>
                    }


                    {missingData?.length > 0 &&
                        <div className='csv-download' onClick={() => handleDownloadCsv(missingData, "unmatchedUser.csv")}>
                            <p>Unmatched User Csv </p>
                        </div>
                    }
                </div>



                <div className='profileInfoBox table'>
                    {!loader ?
                        <>
                            <div style={{ width: '100%' }} className="container app-body">
                                {data.length > 0 ? <>

                                    <div className="tabel-scrol">
                                        <table id="users">
                                            <thead>
                                                <tr>
                                                    <th>S.NO.</th>
                                                    <th>UserName</th>
                                                    <th>Email Id </th>
                                                    <th>UserId</th>
                                                    <th>Phone Number</th>
                                                    <th style={{ width: '130px' }}>last_login_at</th>


                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data?.map((val, key) => (
                                                    <tr key={key}>
                                                        <td>{(filterInfo.limit * filterInfo.page) + (key + 1)}</td>
                                                        <td className='clickabuetd' onClick={() => window.open(`/users/transaction/${val._id}`)}>{val?.name}</td>
                                                        <td>{val?.emailId}</td>
                                                        <td>{val?._id}</td>
                                                        <td>{val?.phoneNumber}</td>
                                                        <td>{val?.last_login_at}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>

                                    </div>

                                    <div>
                                        <Pagination
                                            filterDataInput={filterInfo}
                                            setFilterDataInput={setFilterInfo}
                                            totalPage={totalPage}
                                        />
                                    </div>
                                </> :
                                    <>
                                        <h4 className='h5Heading'>No Data Available</h4>
                                    </>}
                            </div>
                        </> :
                        <>
                            <div className='loaderImage'>
                                <img src={loaderimg} alt='loader' />
                            </div>
                        </>
                    }
                </div>
            </div >



            <Dialog
                open={bulkPopup}
                onClose={() => handleBulkPopup()}
                TransitionComponent={Transition}
                className="dialogs bulkDialog"
            >
                <div className='bulkBox'>
                    <div className='csvUploadDate'>
                        <div className="dFlex w70">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="Select Date"
                                    value={csvDate}
                                    onChange={(value) => setCsvDate(value)}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </div>

                    </div>

                    <div className="select-before-radio">
                        <span>Before Data:</span>
                        <div className="user-select-befor">
                            <p>Before</p>
                            <input type="radio" name="beforeDatatrue" value="true" checked={beforeData == "true"} onChange={(e) => setBeforeData(e.target.value)} />
                        </div>

                        <div className="user-select-befor">
                            <p>After</p>
                            <input type="radio" name="beforeDatafalse" value="false" checked={beforeData == "false"} onChange={(e) => setBeforeData(e.target.value)} />
                        </div>
                    </div>



                    {bulkData.length > 0 ?
                        <div style={{ width: '100%' }} className="container app-body bulkTable">
                            <div className='tabel-scrol'>


                                <table id="users">
                                    <thead>
                                        <tr>
                                            <th style={{ width: '40px' }}>S.NO.</th>
                                            <th style={{ width: '75px' }}>User Email</th>
                                            <th style={{ width: '130px' }}>Number</th>
                                            <th style={{ width: '130px' }}>userId</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentItems.map((val, index) => (
                                            <tr key={index}>
                                                <td>{indexOfFirstItem + index + 1}</td>
                                                <td className='clickable'>{val?.emailId}</td>
                                                <td>{val?.phoneNumber}</td>
                                                <td>{val?.userId}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                            </div>



                            {/* <div className='uploadCsv-pagination'>
                                {<span onClick={() => handlePreviousIndex()}>Previous</span>}
                                {<span onClick={() => handleNextIndex()}>Next</span>}
                            </div> */}





                            <div className='uploadCsv-pagination'>
                                <button
                                    onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                                    disabled={currentPage === 1}
                                >
                                    Previous
                                </button>
                                <button
                                    onClick={() => setCurrentPage(prev => Math.min(prev + 1, Math.ceil(bulkData.length / itemsPerPage)))}
                                    disabled={currentPage === Math.ceil(bulkData.length / itemsPerPage)}
                                >
                                    Next
                                </button>
                            </div>

                        </div> : <p>No Data to load</p>}


                    <div className="user-csv-filter">
                        <div className='bulkEdit' onClick={() => !loader && handleUploadedCsv()}>
                            <p>Submit</p>
                        </div>

                    </div>

                </div>
            </Dialog>


        </>
    )
}

export default UserUploadCsv
