import React, { useEffect, useState } from 'react'
import "../assets/css/SideBar.css"
import logo from "../assets/images/DarkLogo.png"
import { ReactComponent as Config } from "../assets/images/config.svg"
import { ReactComponent as Arrow } from "../assets/images/arrow.svg"
import { ReactComponent as Home } from "../assets/images/home.svg"
import { ReactComponent as Categories } from "../assets/images/categories.svg"
import { ReactComponent as Voucher } from "../assets/images/voucher.svg"
import { useNavigate } from 'react-router-dom'

function SideBar({ handleSideBar, activeTab }) {
    const adminArray = JSON.parse(localStorage.getItem("admin"));
    const Role = adminArray?.role
    const [isSiderbarOpen, setIsSiderbarOpen] = useState(true);
    const navigate = useNavigate()
    const userInfo = JSON.parse(localStorage.getItem("admin"));
    // console.log("userInfo",userInfo)



    const permissionModule = adminArray?.permission_module || [];




    const menuItems = [
        { text: "Home", route: "/Home", icon: <Home className="config" /> },
        { text: "Users", route: "/users", icon: <Config className="config" /> },
        { text: "Upload User CSV", route: "/uploadUserCsv", icon: <Config className="config" /> },
        { text: "UserCsvDataStat", route: "/userCsvDataStat", icon: <Config className="config" /> },
        { text: "UserPartnerBlocked", route: "/userPartnerBlocked", icon: <Config className="config" /> },
        { text: "Partners", route: "/partners", icon: <Voucher className="config" /> },
        { text: "Categories", route: "/categories", icon: <Categories className="config" /> },
        { text: "AddOffers", route: "/offer", icon: <Voucher className="config" /> },
        { text: "AllOffers", route: "/allOffers", icon: <Voucher className="config" /> },
        { text: "Blogs", route: "/addBlog", icon: <Voucher className="config" /> },
        { text: "All Blogs", route: "/allblogs", icon: <Voucher className="config" /> },
        { text: "Transactions", route: "/transaction", icon: <Voucher className="config" /> },
        { text: "Gross Data", route: "/grossData", icon: <Voucher className="config" />, role: 3 },
        { text: "Admins", route: "/admins", icon: <Voucher className="config" />, role: 3 },
        { text: "UserDevice", route: "/deviceInfo", icon: <Voucher className="config" />, role: 3 },
        { text: "StaticOfferApi", route: "/staticOffers", icon: <Voucher className="config" /> },
        { text: "Leaderboard", route: "/leaderboard", icon: <Voucher className="config" /> },
        { text: "Pending Earning", route: "/pendingEarning", icon: <Voucher className="config" /> },
        { text: "PaymentMode", route: "/paypal", icon: <Voucher className="config" /> },
        { text: "AllChallenge", route: "/allchallenge", icon: <Voucher className="config" /> },
        { text: "MultiPageData", route: "/allmultiPageData", icon: <Voucher className="config" /> },
        { text: "ReferLinkPage", route: "/referlinkstat", icon: <Voucher className="config" /> },
        { text: "Configuration", route: "/configurationInfo", icon: <Voucher className="config" /> },
        { text: "MetaConfig", route: "/metaconfig", icon: <Voucher className="config" /> },
    ];


    // Filter menu items based on role and permissions
    const filteredMenuItems = menuItems.filter(item => {
        // Check role-specific items
        if (item.role && item.role !== Role) return false;

        // Check permission module
        // return Role === 3 || permissionModule.includes(item.text);
        return Role === 3 || permissionModule.some((k) => k.module === item.text);
    });





    useEffect(() => {
        handleSideBar ? setIsSiderbarOpen(false) : setIsSiderbarOpen(false)
    }, [handleSideBar])

    const toggleSidebar = () => {
        setIsSiderbarOpen(!isSiderbarOpen);
    };

    return (
        <>
            <div className='hamburgerParentDiv'>
                <div className='hamburger' onClick={toggleSidebar}>{isSiderbarOpen ? "╳" : "☰"}</div>
            </div>
            <div className={`sidebar ${isSiderbarOpen ? "active" : ' '}`}>
                <div className='logoBox'>
                    <img src={logo} alt="logo" />
                </div>

                <div className="menuCover">
                    {filteredMenuItems.map((menu, index) => (
                        <div
                            key={index}
                            className={activeTab === menu.text ? "sideMenu active" : "sideMenu"}
                            onClick={() => navigate(menu.route)}
                        >
                            {menu.icon}
                            <p className="menuName">{menu.text}</p>
                        </div>
                    ))}
                </div>

                <div className='proflieBox'>
                    <div className={`sideMenu profile ${activeTab === "profile" ? "active" : null}`} onClick={() => navigate('/profile')}>
                        <p className='adminName'>{userInfo?.adminUsername?.slice(0, 1)?.toUpperCase()}</p>
                        <Arrow className='arrow' />
                    </div>
                </div>

            </div>
        </>





    )
}

export default SideBar