import React, { useEffect, useState } from 'react'
import SideBar from './SideBar'
import axiosClient from '../axios';

import { Autocomplete, TextField } from "@mui/material";

import loaderimg from '../assets/images/loader.gif'




const UserPartnerBlocked = () => {
    const [partner, setPartner] = useState({
        name: "",
        _id: ""
    });

    console.log("Partner", partner)

    const [allProvider, setAllProvider] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]); // Default to an empty array

    const [blockedUserData, setBlockedUserData] = useState([])

    const [rowPerPage1, setRowPerPage1] = useState(100);
    const [currentPage1, setCurrentPage1] = useState(0);
    const [loading, setLoading] = useState(false)



    console.log("selectedOptions=======>", selectedOptions)

    const options = [
        { value: '1', label: 'Block by Admin' },
        { value: '2', label: 'Postback Limit' },
        { value: '3', label: 'Chargeback Limit' },
    ];

    const handleOptionChange = (event) => {
        const value = event.target.value;
        setSelectedOptions((prevSelectedOptions) => {
            if (prevSelectedOptions.includes(value)) {
                // If the value is already selected, remove it
                return prevSelectedOptions.filter((option) => option !== value);
            } else {
                // If the value is not selected, add it
                return [...prevSelectedOptions, value];
            }
        });
    };


    const getPartner = () => {
        axiosClient
            .get("/partnersList")
            .then((res) => setAllProvider(res.data.response))
            .catch((error) => console.log(error));
    }



    const handleGetBlockedUser = () => {
        if (!partner._id) {
            alert("Please select a partner")
        }

        if(selectedOptions.length == 0){
            alert("Please select a block reason")
            return
        }
        setLoading(true)

        axiosClient.post('/userPartnerblock', { partnerId: partner._id, blockReason: selectedOptions, page: currentPage1, limit: rowPerPage1 })
            .then((res) => {
                console.log("res", res)
                setBlockedUserData(res.data.response)
            })
            .catch((error) => console.log(error))
            .finally(() => setLoading(false))
    }

    const handleUnBlockPartner = (id)=>{
        
        setLoading(true)
        axiosClient.post('/unblockUserPartner', { partnerId: partner._id , userId:id})
        .then((res) => {
            console.log("res", res)
            alert(res.data.message)
            handleGetBlockedUser()
        })
        .catch((error) => console.log(error))
        .finally(() => setLoading(false))
    }






    useEffect(() => {
        if (partner._id) {
            handleGetBlockedUser()
        }
    }, [rowPerPage1, currentPage1])








    useEffect(() => {
        getPartner()
    }, [])

    return (
        <>
            <SideBar handleSideBar={true} activeTab={"UserPartnerBlocked"} />
            <div className="screen">
                <h1>User Partner Blocked</h1>
                <div className="userParterFilter">
                    <div className="partnerDropdown">


                        <Autocomplete
                            disablePortal
                            value={allProvider.find(option => option._id === partner._id) || null}
                            onChange={(event, newValue) => {
                                if (newValue) {
                                    setPartner({ name: newValue.name, _id: newValue._id });
                                } else {
                                    setPartner({ name: "", _id: "" });
                                }
                            }}
                            options={allProvider}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                                <TextField {...params} label="Provider" />
                            )}
                        />

                    </div>

                    <div className='userBlockedReason'>
                        {options.map((option) => (
                            <label key={option.value} style={{ marginRight: '20px' }}>
                                <input
                                    type="checkbox"
                                    value={option.value}
                                    checked={selectedOptions.includes(option.value)}
                                    onChange={handleOptionChange}
                                />
                                {option.label}
                            </label>
                        ))}
                    </div>
                    <button className='fetchBlockedUser' onClick={() => handleGetBlockedUser()} >Get User</button>



                </div>



                {!loading ? <>
                    {blockedUserData.length > 0 ?
                        <div style={{ width: '100%' }} className="container app-body bulkTable">
                            <div className='tabel-scrol'>
                                <table id="users">
                                    <thead>
                                        <tr>
                                            <th style={{ width: '40px' }}>S.NO.</th>
                                            <th style={{ width: '40px' }}>userId</th>
                                            <th style={{ width: '40px' }}>name</th>
                                            <th style={{ width: '75px' }}>emailId</th>
                                            <th style={{ width: '130px' }}>phoneNumber</th>
                                            <th style={{ width: '130px' }}>UnBlock User</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {blockedUserData.map((val, index) => (
                                            <tr key={index}>
                                                <td>{(rowPerPage1 * currentPage1) + index + 1}</td>
                                                <td >{val?.user_id?._id}</td>
                                                <td >{val?.user_id?.name}</td>
                                                <td >{val?.user_id?.emailId}</td>
                                                <td>{val?.user_id?.phoneNumber}</td>
                                                <td><button className='unBlockUser' onClick={() => handleUnBlockPartner(val?.user_id?._id)}>UnBlock User</button></td>

                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                            </div>


                            <div className='userTablePagination'>
                                <label>Rows per page:</label>
                                <select value={rowPerPage1} onChange={(e) => { setRowPerPage1(e.target.value); setCurrentPage1(0) }}>
                                    <option value={1}>1</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                    <option value={500}>500</option>
                                    <option value={1000}>1000</option>
                                    <option value={1500}>1500</option>
                                </select>


                                {currentPage1 !== 0 && (
                                    <button className='paginationButton' onClick={() => setCurrentPage1(currentPage1 - 1)} style={{ backgroundColor: "#2b579a" }}>
                                        Previous
                                    </button>
                                )}

                                {(<button className='paginationButton'
                                    onClick={() => setCurrentPage1(currentPage1 + 1)}
                                >
                                    Next
                                </button>
                                )}
                            </div>



                        </div> : <p>No Data to load</p>}
</>
                    :
                    <div className='loaderImage'>
                        <img src={loaderimg} alt='loader' />
                    </div>}


            </div>
        </>
    )
}

export default UserPartnerBlocked
