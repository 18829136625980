import React from 'react'

const Pagination_2 = ({filterDataInput, setFilterDataInput, showNext}) => {
    const handleLimitChange = (e) => {
        setFilterDataInput({ ...filterDataInput, limit: Number(e.target.value), page: 0 });
    };

    const handlePreviousPage = () => {
        setFilterDataInput({ ...filterDataInput, page: Number(filterDataInput.page - 1) });
    };

    const handleNextPage = () => {
        setFilterDataInput({ ...filterDataInput, page: Number(filterDataInput.page + 1) });
    };
    return (
        <>
            <div className='userTablePagination'>
                <label>Rows per page:</label>
                <select value={filterDataInput.limit} onChange={handleLimitChange}>
                    <option value={10}>10</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                    <option value={500}>500</option>
                    <option value={1000}>1000</option>
                    <option value={1500}>1500</option>
                </select>

                {/* <span className='currentPagespan'>
                    Current Page {filterDataInput.page + 1}/{Math.ceil(totalPage / filterDataInput.limit)}
                </span> */}
                {filterDataInput.page > 0 && (
                    <button
                        className='paginationButton'
                        onClick={handlePreviousPage}
                        style={{ backgroundColor: "#2b579a" }}
                    >
                        Previous
                    </button>
                )}

                { showNext &&(
                    <button
                        className='paginationButton'
                        onClick={handleNextPage}
                        // disabled={filterDataInput.page + 1 >= totalPage / filterDataInput.limit}
                    >
                        Next
                    </button>
                )}
            </div>
        </>
    )
}

export default Pagination_2
