import React, { useEffect, useState } from 'react'
import SideBar from './SideBar'
import { dateConvert } from '../dateConverter';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';
import axiosClient from '../axios';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import loaderimg from '../assets/images/loader.gif'
import Pagination_2 from './Pagination_2';
import { use } from 'react';



const UploadCsvUserStat = () => {
    const [loader, setLoader] = useState(false);
    const [bulkData, setBulkData] = useState([])
    const [bulkDataReq, setBulkDataReq] = useState([])
    const [date, setDate] = useState({
        start: null,
        end: null,
    })
    const [userStatData, setUserStatData] = useState()



    const [rowPerPage1, setRowPerPage1] = useState(100);
    const [currentPage1, setCurrentPage1] = useState(0);



    // csv data table pagination start
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 100; // Adjust this value as needed
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = bulkData.slice(indexOfFirstItem, indexOfLastItem);
    // csv data table pagination end



    const readFileAsText = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => resolve(e.target.result);
            reader.onerror = reject;
            reader.readAsText(file);
        });
    };

    console.log("bulkData", bulkData)


    const handleBulkCsv = async (event) => {
        setLoader(true);
        try {
            // setDataUpdated(false);
            const file = event.target.files[0];
            if (!file) return;

            const text = await readFileAsText(file); // Await file reading

            // Expected headers
            const expectedHeaders = ["emailId", "phoneNumber",];
            const fileHeaders = text.split("\n")[0].split(",").map(header => header.trim());
            // Validate headers
            if (!expectedHeaders.every((header, index) => header === fileHeaders[index])) {
                alert("Invalid CSV Format: Headers should be 'emailId, phoneNumber'");
                return;
            }
            const rows = text.split("\n").slice(1); // Remove header

            const formattedData = rows
                .map((row) => row.split(",")) // Split by comma
                .filter((cols) => cols.length >= 2) // Ensure valid rows
                .map(([emailId, phoneNumber,]) => ({
                    emailId,
                    phoneNumber,
                }));
            if (formattedData.length === 0) {
                alert("Invalid CSV Format")
                return
            }
            console.log("formattedData", formattedData)
            setBulkData(formattedData);
            setBulkDataReq(formattedData);

        } catch (err) {
            console.error("Error:", err);
        } finally {
            setLoader(false);

        }
    };

    const handleDate = (e, f) => {
        const res = dateConvert(e);
        if (f === 1) {
            setDate({ ...date, start: e, })
            return
        }
        if (f === 2) {
            setDate({ ...date, end: e, })
            return
        }
    }


    const handleUserStat = async () => {
        let userData = {
            s_date: dateConvert(date.start),
            e_date: dateConvert(date.end),
            bulkData: bulkDataReq,
            page: currentPage1,
            limit: rowPerPage1
        }

        setLoader(true);
        await axiosClient.post("/user-uploaded-csv-stat", userData)
            .then((res) => {
                console.log('res-', res)
                setBulkData([])
                setUserStatData(res.data.response)
                setLoader(false);

            })
            .catch((err) => {
                console.log('res-', err.response.data.messae)
                alert(err.response.data.messae)
            })
    }


    useEffect(() => {
        if(userStatData?.length > 0){
            handleUserStat()
        }
    }, [rowPerPage1 , currentPage1])


    return (
        <>
            <SideBar handleSideBar={true} activeTab={"UserCsvDataStat"} />
            <div className="screen">
                <div className='dFlex'>
                    <h2 className='screenHeading catHead'>User Csv Data Stat</h2>
                </div>

                <div className="upload-user-csv-flex">
                    <div className="user-upload">
                        <form>
                            <input type={"file"} id='bulkdepositCsv' onChange={handleBulkCsv} accept={".csv"} style={{ display: "none" }} />
                            <label htmlFor="bulkdepositCsv" className='userCsvButton'>Upload Csv</label>
                        </form>
                    </div>



                    <div className='addPartnerButton'>


                        <div className='dFlex alignCenter  '>

                            <div className="dFlex w70">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="Start Date"
                                        value={date.start}
                                        onChange={(value) => handleDate(value, 1)}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </div>

                        </div>
                        <div className='dFlex alignCenter  '>

                            <div className="dFlex w70">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="End Date"
                                        value={date.end}
                                        onChange={(value) => handleDate(value, 2)}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </div>

                        </div>

                        {<button className='tSubnitButton' onClick={() => handleUserStat(bulkData)} >FetchUserStat</button>}

                    </div>


                </div>




                <div className='profileInfoBox table'>
                    {!loader ?
                        <>
                            <div style={{ width: '100%' }} className="container app-body">

                                {userStatData?.length > 0 ? <>

                                    <div className="tabel-scrol">
                                        <table id="users">
                                            <thead>
                                                <tr>
                                                    <th>S.NO.</th>
                                                    <th>name</th>
                                                    <th>Email Id </th>
                                                    <th>Phone Number</th>
                                                    <th style={{ width: '130px' }}>Total Amount</th>
                                                    <th style={{ width: '130px' }}>User Id</th>


                                                </tr>
                                            </thead>
                                            <tbody>
                                                {userStatData?.map((val, key) => (
                                                    <tr key={key}>
                                                        <td>{(rowPerPage1 * currentPage1) + (key + 1)}</td>
                                                        <td className='clickabuetd' onClick={() => window.open(`/users/transaction/${val.userId}`)}>{val?.name}</td>
                                                        <td>{val?.emailId}</td>
                                                        <td>{val?.phoneNumber}</td>
                                                        <td>{val?.totalAmount}</td>
                                                        <td>{val?.userId}</td>


                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>

                                    </div>


                                    <div className='userTablePagination'>
                                        <label>Rows per page:</label>
                                        <select value={rowPerPage1} onChange={(e) => { setRowPerPage1(e.target.value); setCurrentPage1(0) }}>
                                            <option value={1}>1</option>
                                            <option value={50}>50</option>
                                            <option value={100}>100</option>
                                            <option value={500}>500</option>
                                            <option value={1000}>1000</option>
                                            <option value={1500}>1500</option>
                                        </select>

                                        {/* <span className='currentPagespan'> Current Page {currentPage1 + 1}/{Math.ceil(totalPage / rowPerPage1)} </span> */}

                                        {currentPage1 !== 0 && (
                                            <button className='paginationButton' onClick={() => setCurrentPage1(currentPage1 - 1)} style={{ backgroundColor: "#2b579a" }}>
                                                Previous
                                            </button>
                                        )}

                                        {(<button className='paginationButton'
                                            onClick={() => setCurrentPage1(currentPage1 + 1)}
                                        // disabled={currentPage1 + 1 >= totalPage / rowPerPage1}
                                        >
                                            Next
                                        </button>
                                        )}
                                    </div>
                                </> :
                                    <>
                                        <h4 className='h5Heading'>No Data Available</h4>
                                    </>}
                            </div>
                        </> :
                        <>
                            <div className='loaderImage'>
                                <img src={loaderimg} alt='loader' />
                            </div>
                        </>
                    }
                </div>









                {/* // csv data table */}
                <div className='profileInfoBox table'>
                    <>
                        <div style={{ width: '100%' }} className="container app-body">
                            {bulkData.length > 0 &&
                                <div style={{ width: '100%' }} className="container app-body bulkTable">
                                    <div className='tabel-scrol'>
                                        <table id="users">
                                            <thead>
                                                <tr>
                                                    <th style={{ width: '40px' }}>S.NO.</th>
                                                    <th style={{ width: '75px' }}>User Email</th>
                                                    <th style={{ width: '130px' }}>Number</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentItems.map((val, index) => (
                                                    <tr key={index}>
                                                        <td>{indexOfFirstItem + index + 1}</td>
                                                        <td className='clickable'>{val?.emailId}</td>
                                                        <td>{val?.phoneNumber}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>

                                    </div>

                                    <div className='uploadCsv-pagination'>
                                        <button
                                            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                                            disabled={currentPage === 1}
                                        >
                                            Previous
                                        </button>
                                        <button
                                            onClick={() => setCurrentPage(prev => Math.min(prev + 1, Math.ceil(bulkData.length / itemsPerPage)))}
                                            disabled={currentPage === Math.ceil(bulkData.length / itemsPerPage)}
                                        >
                                            Next
                                        </button>
                                    </div>

                                </div>
                            }
                        </div>
                    </>

                </div>


            </div>










        </>
    )
}

export default UploadCsvUserStat
