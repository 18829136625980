import React, { useCallback, useEffect } from 'react'
import SideBar from './SideBar'
import axiosClient from '../axios';
import '../assets/css/userTable.css'
import loader from '../assets/images/loader.gif'
import { useState } from 'react';
import Pagination from './Pagination';
import { debounce } from 'lodash';


const DevicesInfo = () => {

    // const [partners, setPartners] = useState([]);
    const [deviceData, setDeviceData] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    const [searchValue, setSearchValue] = useState("");

    
    const [pagination, setPagination] = useState({
        page: 0,
        limit: 100
    });

    const [totalPage, setTotalPage] = useState(10)
    const [isGetCount, setIsGetCount] = useState(false)




    const getAlldevice = (searchvalue) => {
        setIsLoading(false)
        axiosClient
            .get(`/alluser-device-data?searchValue=${searchvalue}&page=${pagination?.page}&limit=${pagination?.limit}`)
            .then((res) => {
                console.log("res.data.response", res.data.response)
                setDeviceData(res.data.response?.totalData)
                // setTotalPage(res.data.response.totalCount)
            })
            .catch((error) =>
                console.log(error)
            ).finally(() => setIsLoading(true))
    }



    const allDeciceCount = () => {
        // setIsLoading(false)
        setIsGetCount(true)
        axiosClient
            .get(`/allDeciceCount?searchValue=${searchValue}`)
            .then((res) => {

                console.log('res.data.response line 52', res.data.response)
                setTotalPage(res.data.response)
                setIsGetCount(false)

            })
            .catch((error) =>
                console.log(error)
            ).finally(() => setIsLoading(true))
    }



    const handleSearch = useCallback(
        debounce((value) => {
            setSearchValue(value)
            getAlldevice(value);

        }, 500),
        []
    );


    const handleChange = (e) => {
        const { value } = e.target;
        handleSearch(value);
    };



    useEffect(() => {
        getAlldevice();
    }, [pagination.page, pagination.limit])

    useEffect(() => {
        allDeciceCount();
    }, [])
    return (
        <>

            <SideBar handleSideBar={true} activeTab={"UserDevice"} />
            <div className="screen">

                <div className='addPartnerButton'>
                    <div className="deviceHeading50">
                        {isGetCount ? <div className='min-loaderImage'>
                            <img src={loader} width="40px" alt='loader' />
                        </div>

                            : <button type="button" onClick={() => allDeciceCount()} >Get Count</button>}

                        <h2 className='screenHeading'>Total Device : {totalPage} </h2>
                    </div>


                    <div className="Usersearchbar autoWidth">
                        <input
                            type="text"
                            placeholder="Search..."
                            onChange={handleChange}
                        />
                    </div>



                </div>

                <div className='profileInfoBox table'>
                    {isLoading ?
                        <>
                            <div style={{ width: '100%' }} className="container app-body">
                                {deviceData?.length > 0 ? <>
                                    <div className="tabel-scrol">
                                        <table id="users">
                                            <thead>
                                                <tr>
                                                    <th>S.NO.</th>
                                                    <th>Device Id</th>
                                                    <th>user Id</th>
                                                    <th>updatedAt</th>
                                                    <th>App Usage</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {deviceData?.map((val, key) => {
                                                    return (
                                                        <>
                                                            <tr key={key}>
                                                                <td>{(pagination.limit * pagination.page) + (key + 1)}</td>
                                                                <td>{val?.deviceId}</td>
                                                                <td>{val?.rUserId}</td>
                                                                <td>{val?.updatedAt}</td>
                                                                <td>{val?.appUsage.$each?.length}</td>
                                                            </tr>
                                                        </>
                                                    )
                                                })}
                                            </tbody>
                                        </table>

                                    </div>
                                    {/* <div>
                                        <Pagination
                                            filterDataInput={pagination}
                                            setFilterDataInput={setPagination}
                                            totalPage={totalPage}
                                        />
                                    </div> */}
                                </> :
                                    <>
                                        <h4 className='h5Heading'>No Data Available</h4>
                                    </>}
                            </div>
                        </>
                        : <>
                            <div className='loaderImage'>
                                <img src={loader} alt='loader' />
                            </div>
                        </>}

                    <div>
                        <Pagination
                            filterDataInput={pagination}
                            setFilterDataInput={setPagination}
                            totalPage={totalPage}
                        />
                    </div>

                </div>
            </div>

        </>
    )
}

export default DevicesInfo
